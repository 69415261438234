import React from 'react';
import Breadcrumbs from "../breadcrumbs";
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import NoveltyCard from '../novelty-card';
import ContentLoader from "react-content-loader"
import { useLocation } from '@reach/router';
import { orderbyDate } from '../../helpers/helper.novelty';
import '../../../node_modules/animate.css/animate.css';

import {useGetBlogsQuery} from '../../redux/mediacore/blog'

import { useEffect, useState } from 'react';

const Main = ({tags}) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              env { 
                APISERVER
                TENANTID
                CLIENTID
              }
          }
    }`)

    const {env} = realEstate;
    const location = useLocation()
    const [listActives, setListActive] = useState([])
    const [isActiveFilterButton, setIsActiveFilterButton] = useState(false)

    const [filteredNovelties,setFilteredNovelties] = useState([])

    const { data: allBlogData, isLoading } = useGetBlogsQuery(env.CLIENTID);

    const toggleTag = (id) => {
        setIsActiveFilterButton(true)
        if (listActives.find(e => e === id)) {
            if (listActives?.length === 1) {
                setFilteredNovelties(allBlogData?.news);
                setIsActiveFilterButton(false) 
            }
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    useEffect(() => {
        // dispatch(updateFiltersNovelties(listActives));
    }, [listActives])

    useEffect(() => {
        // dispatch(clearNoveltiesAction());
        // dispatch(clearNoveltyAction());
        // dispatch(clearFiltersAction())
        // dispatch(getFiltersNoveltiesAction(env,true));
    } ,[location])

    useEffect(() => {
        if(allBlogData?.news){
            if(filteredNovelties.length===0){
                setFilteredNovelties(orderbyDate(allBlogData.news))
            }
        }
    },[allBlogData])

    const filterNoveltiesAction = () => {
        if (listActives.length === 0) {
            setIsActiveFilterButton(false)
            setFilteredNovelties(allBlogData?.news);
            return;
        }    
        let response = []
        if (allBlogData?.news) {
            for (const novelty of allBlogData?.news) {
                if(novelty.tags.find(t => listActives.find(l => l === t.id))){
                    response.push(novelty);
                }
            }
            setIsActiveFilterButton(false)
        }
        return setFilteredNovelties(response)
    }

  return (
    <section className="novelty-main">
      <div className="black-background ">
        <div className="container-fluid">
          <Breadcrumbs
            props={[
              { name: "Home", route: "/", location: "" },
              { name: "Blog", route: "", location: "" },
            ]}
          />
          <div className="d-flex align-items-center primary-row ">
            <div className={"row align-items-start w-100"}>
              <div
                className={
                  "left-column d-flex align-items-start flex-column d-lg-block col-lg-5 "
                }
              >
                <div className="d-flex flex-column justify-content-center">
                  <h4 className="mb-2">MANTENETE AL TANTO DE</h4>
                  <h2 className="mb-2">
                    Nuestras novedades
                    <br />
                    más destacadas
                  </h2>
                </div>
              </div>

              <div className="col-lg-7 img">
                <img src="/images/novedades-1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row  mt-lg-4 align-items-center">
          <div className='first_div col-12 col-lg-6'>
          <h1 className="title mb-lg-4">Novedades inmobiliaria</h1>
            <h2 className="sub-title">
              Descubre las últimas tendencias y noticias de real estate de Mar del plata y del país.
            </h2>
          </div>
          <div className="second_div col-12 col-lg-6">
            <div className="div_search d-flex justify-content-between">
              <i className="d-none icon-label-solid me-3 d-md-flex"></i>
              <div className="pills_search d-flex justify-content-between">
                <div className="div_pills flex-wrap">
                  {listActives.map((element, index) => (
                    <span className="animate__animated animate__fadeInRight pill btn btn-blue me-2 mb-1 mt-2 mt-lg-0">
                      {
                        allBlogData?.tags.find(item => item.id === element)
                          ?.name
                      }
                      <i
                        onClick={() => toggleTag(element)}
                        className="icon-close-light"
                      ></i>
                    </span>
                  ))}
                  {/* pill */}
                  {!listActives.length && (
                    <div className="placeholder-text">
                      Utilice las etiquetas para filtrar las novedades de su
                      interés.
                    </div>
                  )}
                  {/* Fin pill */}
                </div>
                <div className="div_buttons d-flex">
                  <button
                    className="btn-clear me-3 d-none d-md-block cursor-pointer"
                    onClick={() => {
                      setListActive([])
                      setFilteredNovelties(allBlogData?.news)
                      setIsActiveFilterButton(false)
                    }}
                  >
                    Limpiar
                  </button>
                  <button
                    className={`btn btn-red btn-share d-none d-lg-flex cursor-pointer ${
                      !isActiveFilterButton && "btn-disabled"
                    }`}
                    onClick={() => filterNoveltiesAction()}
                  >
                    Filtrar <i className="icon-search ms-3"></i>
                  </button>
                  <button
                    className={`btn btn-red btn-share-icon d-lg-none cursor-pointer ${
                      !isActiveFilterButton && "btn-disabled"
                    }`}
                    onClick={() => filterNoveltiesAction()}
                  >
                    <i className="icon-search "></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="div_etiquetas flex-wrap">
              {isLoading
                ? [1, 2, 3].map((count, index) => (
                    <div className="me-3 mb-2 d-inline-block" key={index}>
                      <ContentLoader
                        speed={2}
                        width={"10rem"}
                        height={"3.5rem"}
                        viewBox="0 0 2rem 5rem"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    </div>
                  ))
                : allBlogData?.tags.map((tag, index) =>
                    listActives.find(e => e === tag.id) ? (
                      ""
                    ) : (
                      <span
                        key={index}
                        onClick={() => toggleTag(tag.id)}
                        className="animate__animated animate__fadeInDown cursor-pointer pill btn btn-blue me-2 mt-2 mt-lg-0 mb-2 mb-lg-3"
                      >
                        {tag.name}
                      </span>
                    )
                  )}
            </div>
          </div>
          <div className="div_novedades row">
            <div className="col-12 div_card mt-5 d-flex flex-wrap justify-content-center">
              {/* <div className="row"> */}
              {isLoading ? (
                <>
                  {[1, 2, 3, 4].map((count, index) => (
                    <div className="col-lg-6 mb-4" key={index}>
                      <ContentLoader
                        speed={2}
                        width={"100%"}
                        height={"50vh"}
                        viewBox="0 0 100% 50vh"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    </div>
                  ))}
                </>
              ) : filteredNovelties?.length > 0 ? (
                filteredNovelties.map((novelty, index) => (
                  <div className="m-2 col-12 col-lg-3" key={index}>
                    <NoveltyCard
                      tags={allBlogData?.tags}
                      novelty={novelty}
                      hidenTags={true}
                      hidenAuthor={true}
                    />
                  </div>
                ))
              ) : (
                <h5 className="text-center mb-5">
                  No hay resultados para esta busqueda
                </h5>
              )}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default connect(state => ({}), null)(Main)
