import * as React from "react"
import { useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { connect } from "react-redux"
import { useStaticQuery, graphql} from "gatsby"

import {getTagsAction, getNoveltiesAction, getFiltersNoveltiesAction} from "../../redux/noveltiesDucks"

//Components
import Main from '../../components/Novedades/main'

const IndexPage = ({novelties,tags,dispatch}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            env { 
              APISERVER
              TENANTID
              CLIENTID
            }
        }
  }`)
useEffect(() => {
    const { env } = realEstate
    // dispatch(getNoveltiesAction(env))
    // dispatch(getFiltersNoveltiesAction())
    // dispatch(getTagsAction())
  },[])

  return(
  <Layout>
    <Seo title="Novedades" />
    <Main />
  </Layout>
)
}

export default connect(state => ({
  novelties: state.novelties.novelties,
  tags: state.novelties.tags
}),null)(IndexPage);
